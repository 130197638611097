import { getUserLocales } from "get-user-locale";

export function getLanguage(supportedLanguages) {
    const locales = getUserLocales();
    for (const locale of locales) {
        if (supportedLanguages.indexOf(locale) !== -1) {
            return locale;
        }
    }
    return "en";
}