import React, { useEffect } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { getLanguage } from "utils/language";

const NotFoundPage = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    languages {
                        key
                        showLandingPage
                    }
                }
            }
        }
    `);

    const language = getLanguage(data.site.siteMetadata.languages.map(lang => lang.key));
    useEffect(() => {
        window.location.replace("/" + language + "/404/#");
    }, [language]);

    return <div />;
};

export default NotFoundPage;